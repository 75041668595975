import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"

import Quote from "../../../components/quote"
import Morework from "../../../components/more-work"
import Layout from "../../../components/layout"

export default (props) => (  
  <Layout>
  <Helmet 
    bodyAttributes={{class:'work-example grey-four'}}
    title="JayJo Design | Jeffrey Jorgensen | Work | Mixpanel - Email Digests"
  />   
    <div>
      <section className="study">
        <div className="content">
          <div className="wrapper first">
            <p className="title-header grey-two">Mixpanel</p>
            <h1 className="study-header">Email Digest & iOS</h1>
          </div>
          <Img 
            fluid={props.data.hero.childImageSharp.fluid}
            placeholderClassName="screengrab full"
            title="Mixpanel mobile app"
            alt="Mixpanel mobile app final product."
            loading="eager"
          />
        </div>
        <div className="content split">
          <div className="wrapper first">
            <div className="two-col two-thirds">
              <div className="col one">
                <h1 className="purple">"Don't always give users what they ask for."</h1>
                <p className="intro">This quote was continually pushed on us as product designers when evaluating and researching what we were going to build next.</p>
                <p>It didn't mean we weren't supposed to listen to our customers. In fact, it meant quite the opposite. It was our leader's way of getting us to think outside the box, to really dig in and question the <em><b>why</b></em> behind our customers' asks.</p>
                <p>So that's exactly what I did when it came to redesigning Email Digests. I sought out the <b><em>QBQ</em></b>, or <b><em>question behind the question</em></b>.</p>
              </div>
              <div className="col two">
                <div className="purpleBg details">
                  <p className="title-header">Project Deets</p>
                  <ul>
                    <li>Year &#8212; <span>2015</span></li>
                    <li>Role &#8212; <span>Design Lead</span></li>
                    <li>Skills &#8212; <span>UI / UX / Proto</span></li>
                  </ul>
                  <p className="title-header">Product Goals</p>
                  <ul>
                    <li className="diamond">Increase clarity to user</li>
                    <li className="diamond">Boost user engagement</li>
                    <li className="diamond">Update visual design</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="wrapper">
            <h1 className="purple">Where we started...</h1>
          </div>
          <Img
              fluid={props.data.ogDash.childImageSharp.fluid}
              className="screengrab-container"
              placeholderClassName="screengrab purple-shadow"
              title="Email Digests, circa 2009 - 2015."
              alt="Email Digests, circa 2009 - 2015."
              style={{
                overflow: "hidden",
                borderRadius: "5px",
                boxShadow: "0 2px 54px 0px rgba(25,26,29, .25)"
              }}
              // imgStyle={{}}
            />
          <div className="caption">Email Digests, circa 2009 - 2015.</div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">Time to talk with some customers.</h1>
            <p>My research for this project consisted of collaborating with the support and sales team to gather as much customer information that I could, whether those were current customers or customers that we had churned or we hadn't closed.</p>
          </div>
          <div className="wrapper">
            <div className="two-col with-image left-side-image">
              <div className="col one">
                <p>I compiled all of that data and found the most common themes (feature requests, complaints, product gaps, etc.). I surveyed several of Email Digest power users and began reaching out and interviewing those who we were willing to let me dive in a little deeper.</p>
              </div>
              <div className="col two">
                <Img
                  fluid={props.data.synth.childImageSharp.fluid}
                  className="screengrab-container"
                  placeholderClassName="screengrab purple-shadow" 
                  alt="Trello board synthesizing data and response collection from customer interviews." 
                  title="Trello board synthesizing data and response collection from customer interviews."
                  style={{
                    overflow: "hidden",
                    borderRadius: "5px",
                    boxShadow: "0 2px 54px 0px rgba(25,26,29, .25)",
                    width: "100"
                  }}
                  imgStyle={{
                    objectFit: "fill"
                  }}
                />
                <div className="caption">Trello board synthesizing data and response collection from customer interviews.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="wrapper">
            <h1 className="purple">The vast majority of customers wanted three things:</h1>
            <div className="two-col half">
              <div className="col one">
                <div className="box">
                  <h3>Support for other products</h3>
                </div>
                <div className="box">
                  <h3>Better frequency options</h3>
                </div>
                <div className="box">
                  <h3>More flexible nomenclature</h3>
                </div>
              </div>
              <div className="col two">
                <div className="explanation">
                  <p className="grey-two">Email digests only supported Trends, one of Mixpanel's older, less-powerful reports.</p>
                </div>
                <div className="explanation">
                  <p className="grey-two">Digests could only be sent out daily, weekly, or monthly.</p>
                </div>
                <div className="explanation">
                  <p className="grey-two">Name of the digest inherited whatever name the user gave it when they bookmarked it.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper narrow-med">
            <p className="centered">When asked what they loved most about Email Digests, the answer was almost always something to the effect of:</p>
          </div>
          <div className="wrapper">
            <h1 className="study-header reversed quote"><span>"I love being able to roll over before I get out of bed and look through my alerts."</span></h1>
          </div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">Bam. Boom. BINGO.</h1>
            <p>While the customers <em><b>asked</b></em> for better Email Digests with more flexibility, what they <em><b>really wanted</b></em> was the ability to look at their data whenever, and wherever they were.</p>
          </div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">Let's give 'em a mobile app.</h1>
            <p>Up to that point Mixpanel hadn't built a mobile app. The thought of Mixpanel queries with potentially thousands of permutations working effectively on a mobile device seemed silly.</p>
            <p>But, Digests typically consisted of teams' and companies' KPIs &#8212; there wasn't a need to dig deep into the data for this mobile app use case.</p>
          </div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">Wireframes FTW.</h1>
            <p>We knew the idea of scrapping Email Digests altogether was going to be a hard sell, so I collaborated with the engineering lead to establish what we felt was a good V1 to pitch to the PM.</p>
            <p>The result was the following application flows.</p>
            <div className="break"></div>
          </div>
          <a className="image-link" href="/static/33d6f7f4c9b964b19109a40d8c494b67/3ba25/Mobile-App-Flow-1.0-wires%402x.png" rel="noopener noreferrer" target="_blank">
            <Img 
              fluid={props.data.wires1.childImageSharp.fluid}
              className="screengrab-container full"
              placeholderClassName="screengrab purple-shadow full"
              alt="One of the wireframe flows used to pitch the idea. Click image to see more." 
              title="One of the wireframe flows used to pitch the idea. Click image to see more."
            />
          </a>
          <div className="caption">One of the wireframe flows used to pitch the idea. Click image to see more.</div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <p>After some pushing for what the customer data showed was right, we won our fearless leader over, and he  was ecstatic. We finally had a reason to lay the foundation for MP-mobile, however he felt it could ultimatley be simplified.</p>
            <p>We rushed out of the conference room and immediately got to work iterating, getting the wireframes in front of the customers we'd initially interviewed to get their thoughts, and to see where and if they'd have troubles.</p>
            <p>After multiple design sessions, critiques, and usability tests, we landed at the flows below, ultimately shedding a couple steps in the report creation flow, removing a report view, and adding a summary, dashboard-like screen.</p>
            <div className="break"></div>
          </div>
          <a className="image-link" href="/static/e22e98af6e855265c0a15ca9b55790ad/3ba25/Mobile-App-Flow-2.0-wires%402x.png" rel="noopener noreferrer" target="_blank">
            <Img
              fluid={props.data.wires2.childImageSharp.fluid}
              className="screengrab-container full"
              placeholderClassName="screengrab purple-shadow full"
              alt="A flow from the final wireframes for the Mixpanel iOS app. Click image to see more."
              title="A flow from the final wireframes for the Mixpanel iOS app. Click image to see more."
            />
          </a>
          <div className="caption">A flow from the final wireframes for the Mixpanel iOS app. Click image to see more.</div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">The bar for visual design is high at Mixpanel.</h1>
            <p>We were in the midst of the initial stages of a complete visual overhaul for Mixpanel. We started with the marketing site, but hadn't touched the product yet.</p>
            <p>So as a design team, we decided to explore some new visual directions for Mixpanel's product, figuring the mobile app could be a great introduction of the new interface patterns.</p>
            <div className="break"></div>
          </div>
          <a className="image-link" href="/static/a871e86f4c4ab95915a64d8672a8b1a6/3ba25/UI%402x.png" rel="noopener noreferrer" target="_blank">
            <Img
              fluid={props.data.iterations.childImageSharp.fluid}
              className="screengrab-container full"
              placeholderClassName="screengrab purple-shadow full"
              alt="A variety of views and visual design iterations for the Mixpanel iOS app. Click image to see more."
              title="A variety of views and visual design iterations for the Mixpanel iOS app. Click image to see more."
            />
          </a>
          <div className="caption">A variety of views and visual design iterations for the Mixpanel iOS app. Click image to see more.</div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <p>We eventually settled on the application views below, merging our clean, focused UI for reports and analytics, and our new gradient theme for things like onboarding and supporting views.</p>
            <div className="break"></div>
          </div>
          <a className="image-link" href="/static/f00a241abf3844121769166c4e15ca6e/3ba25/Mobile-App-Flow-1.0-screens%402x.png" rel="noopener noreferrer" target="_blank">
            <Img
              fluid={props.data.finalUI.childImageSharp.fluid}
              className="screengrab-container full"
              placeholderClassName="screengrab purple-shadow full"
              alt="The final visual design for the Mixpanel mobile iOS app. Click image to see more."
              title="The final visual design for the Mixpanel mobile iOS app. Click image to see more."
            />
          </a>
          <div className="caption">The final visual design for the Mixpanel mobile iOS app. Click image to see more.</div>
        </div>
        <div className="content">
          <div className="wrapper">
            <h1 className="purple">Product performance and success:</h1>
            <div className="three-col thirds">
              <div className="col">
                <div className="box">
                  <p className="result">6</p>
                  <p className="grey-two small centered">Number of weeks to design, build, and ship. (2 weeks longer than original timeline for Email Digests.)</p>
                </div>
              </div>
              <div className="col">
                <div className="box">
                  <p className="result">72</p>
                  <p className="grey-two small centered">Percent retention rate for users who downloaded the app and created a report.</p>
                </div>
              </div>
              <div className="col">
                <div className="box">
                  <p className="result">4.8</p>
                  <p className="grey-two small centered">Star rating in the Apple iOS App Store to this day.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <Quote
              quoteText="Jeff is incredibly easy to work with in that he is a responsive listener that balances his own opinion and peoples' feedback. I respect how much he works to be technical and code literate in a org that doesn't require it. He leads the team in culture and general creativity. I have yet to work with a designer that is as well-rounded, both professionally and personally."
              subject="- Engineering Colleague, 2016"
            />
          </div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <div className="done-differently">
              <h1>Things I would do differently</h1>
              <p className="intro">I would speak with more executives and broaden the userbase/usertype we interviewed.</p>
              <p>After launch, we learned people lower in the organization would often forward KPIs onto higher-level colleagues who weren’t regular Mixpanel users. Mixpanel mobile didn’t afford them the ability to do this, and thus we had to re-enable the Digests feature for ~20 companies.</p>
              <p>Two years later we eventually redesigned and rebuilt Email Digests making them move robust and powerful.</p>
            </div>
          </div>
        </div>
        <Morework 
          prevURL="/work/mixpanel/insights"
          prevWorkTitle="Mixpanel Insights"
          nextURL="/work/mixpanel/design-system"
          nextWorkTitle="Mixpanel Design System"
          purpleText="Feel like working together?"
          buttonText="Let's Do This"
        />
      </section>
    </div>
  </Layout>
)

// Create a fragment for all images on the page
export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid
        src
      }
    }
  }
`

// Get the Site Meta Data & JayJo Links
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    hero: file(relativePath: {eq: "work/mixpanel/email-ios/MixpanelMobileAppFlow.png"}) {
      ...fluidImage
    }
    ogDash: file(relativePath: {eq: "work/mixpanel/email-ios/email-digests-screenshot@2x.png"}) {
      ...fluidImage
    }
    synth: file(relativePath: {eq: "work/mixpanel/email-ios/digest-interview-synthesis-half@2x.png"}) {
      ...fluidImage
    }
    wires1: file(relativePath: {eq: "work/mixpanel/email-ios/Mobile-App-Flow-1.0-wires@2x.png"}) {
      ...fluidImage
    }
    wires2: file(relativePath: {eq: "work/mixpanel/email-ios/Mobile-App-Flow-2.0-wires@2x.png"}) {
      ...fluidImage
    }
    iterations: file(relativePath: {eq: "work/mixpanel/email-ios/UI@2x.png"}) {
      ...fluidImage
    }
    finalUI: file(relativePath: {eq: "work/mixpanel/email-ios/Mobile-App-Flow-1.0-screens@2x.png"}) {
      ...fluidImage
    }
  }
`